import { useQuery, gql } from "@apollo/client";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

import ProjectInfo from "../ProjectInfo";

import screen from "./screen.png";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  background: black;
  width: 100%;
  min-height: 100%;
  padding: 4rem;
`;

const Title = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 8rem;
  line-height: 10rem;
  padding: 0 0 2rem;
  color: #efefef;
`;

const DisplayBoxes = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const DisplayBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 2rem 4rem 0;
`;

const DisplayWrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding: 2rem 2rem;
  width: 20rem;
  background: #181818;
  text-decoration: none;
  :hover {
    background: #252525;
  }
  :last-of-type {
    border-bottom: unset;
  }
`;

const Img = styled.img`
  width: 2rem;
  height: 2rem;
  margin: 0 2rem 0 0rem;
`;

const H1 = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  color: #efefef;
  font-size: 1.2rem;
  line-height: 1.3rem;
`;

const H2 = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  color: #efefef;
  font-size: 1.2rem;
  line-height: 1.3rem;
`;

function Display({ synced, display }) {
  return (
    <DisplayWrapper
      to={`/display/${synced ? display.name : `none/${display.name}`}`}
    >
      <Img src={screen} alt="" />
      <div>
        <H1>{display.name}</H1>
        <H2>{display?.displayView?.name}</H2>
      </div>
    </DisplayWrapper>
  );
}

function DisplayList() {
  const { data } = useQuery(DISPLAYS_QUERY, {
    pollInterval: 10000,
  });
  return (
    <DisplayBoxes>
      <Title>displays</Title>
      <DisplayBox>
        {data &&
          data.displays.map((display) => {
            return <Display synced={true} display={display} key={display.id} />;
          })}
      </DisplayBox>
      <Title>views</Title>
      <DisplayBox>
        <Display display={{ name: "clock" }} />
        <Display display={{ name: "cinema" }} />
        <Display display={{ name: "deadline" }} />
        <Display display={{ name: "blank" }} />
      </DisplayBox>
    </DisplayBoxes>
  );
}

export default function LandingView() {
  return (
    <Wrapper>
      <DisplayList />
      <ProjectInfo />
    </Wrapper>
  );
}

const DISPLAYS_QUERY = gql`
  query Displays {
    displays {
      id
      name
      displayView {
        name
      }
    }
  }
`;
