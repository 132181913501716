import styled from "@emotion/styled/macro";

const ConversationsWrapper = styled.div`
  flex: 0 0 33.33%;
  display: flex;
  flex-direction: column;
  background: #222222;
  border: 2px solid black;
`;

export default function Conversations() {
  return <ConversationsWrapper></ConversationsWrapper>;
}
