import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled/macro";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import randomWords from "random-words";
import * as Sentry from "@sentry/react";

import useLocalStorage from "../../helpers/useLocalStorage";
import useInterval from "../../helpers/useInterval";
import ProjectInfo from "../ProjectInfo";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  background: black;
  width: 100%;
  height: 100%;
  padding: 4rem;
`;

const Title = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 8rem;
  line-height: 10rem;
  padding: 0 0 2rem;
  color: #efefef;
`;

const H1 = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  color: #efefef;
  font-size: 1.2rem;
  line-height: 1.3rem;
`;

const H2 = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  color: #efefef;
  font-size: 1.2rem;
  line-height: 1.3rem;
`;

export default function Auth() {
  // setup to localstore
  let [jwt, setJWT] = useLocalStorage("jwt");

  // generate screen credentials
  let [display] = useState({
    username: `${randomWords(1)}-${uuidv4()}`,
    password: uuidv4(),
  });

  // create an account
  let [register, registerMutation] = useMutation(REGISTER_MUTATION, {
    variables: {
      username: `${display.username}`,
      email: `${display.username}@example.com`,
      password: `${display.password}`,
    },
    onError: (error) => {
      console.error("register has failed");
      Sentry.setContext("user", {
        name: display.username,
      });
      Sentry.captureException(
        Error("Authentication error: Register has failed"),
        {
          tags: {
            component: "auth",
            user: display.username,
          },
          level: "error",
        }
      );
    },
  });
  !registerMutation.called && register();

  // login with the display id and keep checking for it
  let [login, loginMutation] = useMutation(LOGIN_MUTATION, {
    variables: {
      identifier: `${display.username}`,
      password: `${display.password}`,
    },
    onCompleted: (data) => {
      // set jwt to local storage
      loginMutation.data?.login?.jwt !== jwt && setJWT(data.login.jwt);
    },
    onError: (error) => {
      console.info("login has failed");
      Sentry.setContext("user", {
        name: display.username,
      });
      Sentry.captureException(Error("Authentication error: login has failed"), {
        tags: {
          component: "auth",
          user: display.username,
        },
        level: "debug",
      });
    },
  });
  useInterval(login, 10000);
  // only login after register
  registerMutation?.data && !loginMutation.called && login();

  let loginError =
    loginMutation?.error?.graphQLErrors?.[0]?.extensions?.exception?.data
      ?.data?.[0]?.messages?.[0]?.message;

  return (
    <Wrapper>
      <Title>display id</Title>
      <Title>{display.username.split("-", 2).join("-")}</Title>
      <H1>register</H1>
      <H2>
        attempted: {registerMutation.called ? "true" : "false"} |{" "}
        {registerMutation.data &&
          `registered as: ${registerMutation?.data?.register?.user?.username}`}
      </H2>
      <br></br>
      <H1>login</H1>
      <H2>
        attempted: {loginMutation.called ? "true" : "false "} | loading:{" "}
        {loginMutation.loading ? "true" : "false"} |{" "}
        {loginError && `Error: ${loginError}`}
      </H2>
      <br />
      <ProjectInfo />
    </Wrapper>
  );
}

const LOGIN_MUTATION = gql`
  mutation($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
    }
  }
`;

const REGISTER_MUTATION = gql`
  mutation($username: String!, $email: String!, $password: String!) {
    register(
      input: { username: $username, email: $email, password: $password }
    ) {
      user {
        username
      }
    }
  }
`;
