import { Global } from "@emotion/react/macro";
import { ApolloProvider, useQuery, gql } from "@apollo/client";
import client from "./api/apolloClient";
import {
  Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Redirect,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import useLocalStorage from "./helpers/useLocalStorage";

import ClockContainer from "./components/Clock";
import Shotgun from "./components/Shotgun";
import CinemaViewContainer from "./components/CinemaView";
import Deadline from "./components/Deadline";
import DisplaySelector from "./components/DisplaySelector";
import Blank from "./components/Blank";
import Video from "./components/Video";
import Auth from "./components/Auth";

import { cssReset, fonts } from "./globalStyles";

function App({ history }) {
  let [jwt] = useLocalStorage("jwt");
  let loggedIn = jwt;
  return (
    <Sentry.ErrorBoundary
      fallback={"An error has occurred"}
      beforeCapture={(scope) => {
        scope.setTag("component", "app");
      }}
    >
      <Router history={history}>
        <ApolloProvider client={client}>
          <Global styles={cssReset} />
          <Global styles={fonts} />
          {loggedIn && (
            <Switch>
              <Route path="/display/:displayName">
                <ScreenRouter />
              </Route>
              <DisplaySelector />
            </Switch>
          )}
          {!loggedIn && <Auth />}
        </ApolloProvider>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

function ScreenRouter() {
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  let { displayName } = useParams();

  const { data } = useQuery(DISPLAY_SCHEDULE_ITEMS_QUERY, {
    variables: { displayName },
    pollInterval: 10000,
  });

  let location = data?.displays?.[0]?.displayView?.type;

  // set the display context so that bug fixing becomes a bit easier
  Sentry.setContext("display", data?.displays?.[0]);

  return (
    <Switch>
      {location && location !== pathname.split("/")[3] && (
        <Redirect to={`${path}/${location}`} />
      )}
      <Route path={`${path}/cinema`}>
        <CinemaViewContainer />
      </Route>
      <Route path={`${path}/deadline`}>
        <Deadline />
      </Route>
      <Route path={`${path}/video`}>
        <Video />
      </Route>
      <Route path={`${path}/shotgun`}>
        <Shotgun />
      </Route>
      <Route path={`${path}/blank`}>
        <Blank />
      </Route>
      <Route path={[`${path}/clock`, ""]}>
        <ClockContainer />
      </Route>
    </Switch>
  );
}

const DISPLAY_SCHEDULE_ITEMS_QUERY = gql`
  query Displays($displayName: String!) {
    displays(where: { name: $displayName }) {
      name
      displayView {
        type
      }
    }
  }
`;

export default Sentry.withProfiler(App);
