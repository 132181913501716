import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

export default function Time({ className }) {
  let [timeStamp, setTimeStamp] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStamp(dayjs());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  let time = timeStamp.format("H:mm");

  return <h1 className={className}>{time}</h1>;
}
