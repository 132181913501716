import styled from "@emotion/styled/macro";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  background: black;
  width: 100%;
  height: 100%;
`;

// const Title = styled.h1`
//   font-family: "ScandiaWebBold", sans-serif;
//   font-size: 8rem;
//   line-height: 10rem;
//   padding: 0 0 2rem;
//   color: #efefef;
// `;

const Video = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export default function VideoPlayer() {
  return (
    <Wrapper>
      <Video playsInline muted autoPlay loop>
        <source src="" type="video/mp4" />
      </Video>
    </Wrapper>
  );
}
