import styled from "@emotion/styled/macro";
import ProjectStatusBar from "./ProjectStatusBar";
import rectangle from "./rectangle.png";

// typgraphy
const LargeTitle = styled.h1`
  padding-top: 0.8rem;
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 6rem;
  line-height: 6rem;
  vertical-align: center;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  text-align: center;
`;

const Text = styled.p`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 1.5rem; // 1.5
  line-height: 1.5rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

// blocks
const DeadlinesBlock = styled.div`
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #222222;
  border: 2px solid black;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem 0 1rem;
`;

const ProjectThumb = styled.img`
  width: 10.66666rem;
  margin-right: 2rem;
`;

const Body = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0 1rem;
`;

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  width: 10.66666rem;
`;

const CounterHeader = styled.div`
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 10.66666rem;
  padding: 0.5rem 0 0 0;
  background: #000000;
`;

const CounterBody = styled.div`
  display: flex;
  justify-content: center;
  background: #efefef;
  height: 6.9rem;
  width: 10.66666rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListItemWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1.2rem;
`;

const ProgressBar = styled.div`
  flex: 0 1 40%;
  display: flex;
  height: 1.5rem;
  padding: 0 2rem;
`;

const ProgressBarCompleted = styled.div`
  background: #a8a8a8;
  width: ${({ deadline }) => deadline.progress};
`;

const ProgressBarRemaining = styled.div`
  flex: 1;
  background: #444444;
`;

const StyledProjectStatusBar = styled(ProjectStatusBar)`
  justify-self: flex-end;
`;

function ListItem({ deadline }) {
  return (
    <ListItemWrapper>
      <ProgressBar>
        <ProgressBarCompleted deadline={deadline} />
        <ProgressBarRemaining deadline={deadline} />
      </ProgressBar>
      <Text>{deadline.name}</Text>
    </ListItemWrapper>
  );
}

export default function Deadlines({ project }) {
  return (
    <DeadlinesBlock>
      <Header>
        <ProjectThumb src={rectangle} />
        <LargeTitle>{project.title}</LargeTitle>
      </Header>
      <Body>
        <Counter>
          <CounterHeader>
            <Text>days left</Text>
          </CounterHeader>
          <CounterBody>
            <LargeTitle textColor="#000">{project.daysLeft}</LargeTitle>
          </CounterBody>
        </Counter>
        <List>
          {project.deadlines.map((deadline, key) => {
            return <ListItem key={key} deadline={deadline} />;
          })}
        </List>
      </Body>
      <StyledProjectStatusBar type="large" project={project} />
    </DeadlinesBlock>
  );
}
