import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { Integrations } from "@sentry/tracing";

const history = createBrowserHistory();

process.env.NODE_ENV !== "development" &&
  Sentry.init({
    dsn:
      "https://8d8c9d80c5b5414c9ffd339477526335@o530653.ingest.sentry.io/5650335",
    environment: process.env.REACT_APP_BITBUCKET_BRANCH,
    release: process.env.REACT_APP_VERSION,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });

// Strict mode is disabled because of a bug in Apollo!
// https://github.com/apollographql/apollo-client/issues/7404
// <React.StrictMode>

ReactDOM.render(<App history={history} />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
