import { useState } from "react";

import styled from "@emotion/styled/macro";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { useQuery, gql } from "@apollo/client";
import { Redirect, useParams } from "react-router-dom";

import OnAir from "./OnAir";
import Footer from "./Footer";
import {
  isCurrentCinemaScheduleItem,
  isPaddedEndPassed,
} from "./timePaddingHelpers";
import useInterval from "../../helpers/useInterval";

import wallpaper from "./wallpaper.jpg";
import planetxLogo from "./plx_logo.png";

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const CinemaViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: black;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ overlayImages }) =>
      overlayImages &&
      overlayImages
        .map(
          (overlayImage) =>
            `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${overlayImage.url}")`
        )
        .join(", ")};
  }
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ backgroundImages }) =>
      backgroundImages?.length
        ? backgroundImages
            .map(
              (backgroundImage) =>
                `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${backgroundImage.url}"), `
            )
            .join("")
        : `no-repeat center/cover url(${wallpaper}), `}
    black;
`;

const PlXLogoImg = styled.img``;

const CalanderWrapper = styled.div`
  flex: 1;
  padding: 0rem 3rem 0;
`;

const CalendarTitle = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 12.5rem;
  line-height: 12.5rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  text-align: center;
`;

const CalandarItemsWrapper = styled.div`
  padding: 4rem 0 0;
`;

const CalendarItemWrapper = styled.div`
  border-top: 2px solid
    ${({ textColor }) => (textColor ? textColor : "#efefef")};
  padding: 1.5rem 0 1.5rem;
`;

const DateTimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 3.5rem; // 3.43
  line-height: 4rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const SubTitle = styled.h3`
  font-family: ${({ important }) =>
    important ? '"ScandiaWebBold", sans-serif' : "Fresco"};
  font-size: 2rem; // 2.06
  line-height: 3rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const Text = styled.p`
  font-family: "Fresco";
  font-size: 1.5rem; // 1.5
  line-height: 3rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

function CalendarItem({ cinemaScheduleItem, textColor }) {
  let startDateTime = dayjs(cinemaScheduleItem.startDateTime);
  let endDateTime = dayjs(cinemaScheduleItem.endDateTime);
  let parsedDate = startDateTime.isToday()
    ? "Today"
    : startDateTime.isTomorrow()
    ? "Tomorrow"
    : startDateTime.format("DD MMMM YYYY");
  let important = startDateTime.isToday() || startDateTime.isTomorrow();

  return (
    <CalendarItemWrapper key={cinemaScheduleItem.id} textColor={textColor}>
      <DateTimeWrapper>
        <SubTitle important={important} textColor={textColor}>
          {parsedDate}
        </SubTitle>
        <SubTitle important={important} textColor={textColor}>
          {startDateTime.format("H:mm")} - {endDateTime.format("H:mm")}
        </SubTitle>
      </DateTimeWrapper>
      <Title textColor={textColor}>{cinemaScheduleItem.title}</Title>
      <Text textColor={textColor}>{cinemaScheduleItem.details}</Text>
    </CalendarItemWrapper>
  );
}

function CinemaSchedule({
  cinemaScheduleItems,
  backgroundImages,
  overlayImages,
  onAir = false,
  textColor,
}) {
  let filteredCinemaScheduleItems = cinemaScheduleItems?.filter(
    (cinemaScheduleItem) => {
      return !isPaddedEndPassed(cinemaScheduleItem);
    }
  );
  return (
    <CinemaViewWrapper overlayImages={overlayImages}>
      <Main backgroundImages={backgroundImages}>
        <PlXLogoImg src={planetxLogo} alt="" />
        {onAir ? (
          <OnAir />
        ) : (
          <CalanderWrapper>
            <CalendarTitle textColor={textColor}>cinema</CalendarTitle>
            <CalandarItemsWrapper>
              {filteredCinemaScheduleItems?.map((cinemaScheduleItem) => {
                return (
                  <CalendarItem
                    cinemaScheduleItem={cinemaScheduleItem}
                    textColor={textColor}
                    key={cinemaScheduleItem.id}
                  />
                );
              })}
              {filteredCinemaScheduleItems?.length === 0 && (
                <CalendarItemWrapper>
                  <Text textColor={textColor}>No events scheduled</Text>
                </CalendarItemWrapper>
              )}
            </CalandarItemsWrapper>
          </CalanderWrapper>
        )}
      </Main>
      <Footer textColor={textColor} />
    </CinemaViewWrapper>
  );
}

export default function CinemaScheduleContainer() {
  // set and make sure the time gets updated so new data is requested
  // requesting data until 24 hours ago because the padding might be that long padding
  const [dayAgo, setDayAgo] = useState(
    dayjs().subtract(24, "hour").startOf("minute").toISOString()
  );
  useInterval(() => {
    setDayAgo(dayjs().subtract(24, "hour").startOf("minute").toISOString());
  }, 1000);

  // DIRTY: Setup an additional cache for the data
  const [cachedData, setCachedData] = useState();

  let { displayName } = useParams();
  useQuery(CINEMA_SCHEDULE_ITEMS_QUERY, {
    variables: {
      displayName,
      dayAgo,
    },
    onCompleted: (data) => {
      setCachedData(data);
    },
    pollInterval: 10000,
  });

  let currentCinemaScheduleItem = cachedData?.cinemaScheduleItems.find(
    isCurrentCinemaScheduleItem
  );

  if (currentCinemaScheduleItem)
    return (
      <Redirect
        to={`/display/${displayName}/cinema/poster/${currentCinemaScheduleItem?.cinemaPoster?.id}`}
      />
    );

  return (
    <CinemaSchedule
      cinemaScheduleItems={cachedData?.cinemaScheduleItems}
      onAir={cachedData?.cinemaConfig?.onAir}
      backgroundImages={
        cachedData?.displays?.[0]?.displayView?.backgroundImages
      }
      overlayImages={cachedData?.displays?.[0]?.displayView?.overlayImages}
      textColor={cachedData?.displays?.[0]?.displayView?.textColor}
    />
  );
}

const CINEMA_SCHEDULE_ITEMS_QUERY = gql`
  query CinemaScheduleItemsAndCinemaConfigAndDisplay(
    $displayName: String!
    $dayAgo: String!
  ) {
    cinemaScheduleItems(
      sort: "startDateTime:ASC"
      where: { endDateTime_gt: $dayAgo }
    ) {
      id
      startDateTime
      startTimePadding
      endDateTime
      endTimePadding
      title
      details
      cinemaPoster {
        id
      }
    }
    cinemaConfig {
      onAir
    }
    displays(where: { name: $displayName }) {
      displayView {
        backgroundImages {
          url
        }
        overlayImages {
          url
        }
        textColor
      }
    }
  }
`;
