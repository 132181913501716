import { useState } from "react";

import styled from "@emotion/styled/macro";
import { useQuery, gql } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import { isCurrentCinemaScheduleItem } from "./timePaddingHelpers";

import OnAir from "./OnAir";
import Footer from "./Footer";

import useInterval from "../../helpers/useInterval";

import planetxLogo from "./plx_logo.png";
import wallpaper from "./wallpaper.jpg";

const CinemaViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: black;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ overlayImages }) =>
      overlayImages &&
      overlayImages
        .map(
          (overlayImage) =>
            `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${overlayImage.url}")`
        )
        .join(", ")};
  }
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ backgroundImages }) =>
        backgroundImages?.length &&
        backgroundImages
          .map(
            (backgroundImage) =>
              `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${backgroundImage.url}"), `
          )
          .join("")}
      no-repeat center/cover url(${wallpaper}),
    black;
`;

const PlXLogoImg = styled.img``;

const InfoWrapper = styled.div`
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 5rem 0 4rem;
`;

const Title = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 5rem;
  line-height: 7rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const SubTitle = styled.h2`
  padding: 0 0 4rem;
  font-family: "Fresco";
  font-size: 3rem;
  line-height: 3rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const SmallTitle = styled.h3`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 3rem;
  line-height: 4rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const Label = styled.span`
  font-family: "Fresco";
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  font-size: 2rem;
  font-weight: 400;
`;

function CinemaPoster({
  cinemaPoster,
  backgroundImages,
  overlayImages,
  textColor,
  onAir = false,
}) {
  return (
    <CinemaViewWrapper overlayImages={overlayImages}>
      <Main backgroundImages={backgroundImages}>
        <PlXLogoImg src={planetxLogo} alt="" />
        {onAir && <OnAir />}
        <InfoWrapper>
          <Title textColor={textColor}>{cinemaPoster?.title}</Title>
          <SubTitle textColor={textColor}>{cinemaPoster?.subTitle}</SubTitle>
          {cinemaPoster?.producer && (
            <SmallTitle textColor={textColor}>
              <Label textColor={textColor}>producer </Label>
              {cinemaPoster?.producer}
            </SmallTitle>
          )}
          {cinemaPoster?.director && (
            <SmallTitle textColor={textColor}>
              <Label textColor={textColor}>director </Label>
              {cinemaPoster?.director}
            </SmallTitle>
          )}
        </InfoWrapper>
      </Main>
      <Footer />
    </CinemaViewWrapper>
  );
}

export default function CinemaPosterContainer() {
  // set and make sure the time gets updated so new data is requested
  // requesting data until 24 hours ago because the padding might be that long padding
  const [dayAgo, setDayAgo] = useState(
    dayjs().subtract(24, "hour").startOf("minute").toISOString()
  );
  useInterval(() => {
    setDayAgo(dayjs().subtract(24, "hour").startOf("minute").toISOString());
  }, 1000);

  // DIRTY: Setup an additional cache for the data
  const [cachedData, setCachedData] = useState();

  let { posterId, displayName } = useParams();

  useQuery(CINEMA_POSTER_QUERY, {
    variables: {
      posterId,
      displayName,
      dayAgo,
    },
    onCompleted: (data) => {
      setCachedData(data);
    },
    pollInterval: 10000,
  });

  let currentCinemaScheduleItem = cachedData?.cinemaScheduleItems.find(
    isCurrentCinemaScheduleItem
  );

  if (cachedData && !currentCinemaScheduleItem)
    return <Redirect to={`/display/${displayName}/cinema/schedule`} />;

  return (
    <CinemaPoster
      cinemaPoster={cachedData?.cinemaPoster}
      onAir={cachedData?.cinemaConfig?.onAir}
      backgroundImages={
        cachedData?.cinemaPoster?.backgroundImages ||
        cachedData?.displays?.[0]?.displayView?.backgroundImages
      }
      overlayImages={cachedData?.displays?.[0]?.displayView?.overlayImages}
      textColor={cachedData?.displays?.[0]?.displayView?.textColor}
    />
  );
}

const CINEMA_POSTER_QUERY = gql`
  query CinemaPoster($posterId: ID!, $displayName: String!, $dayAgo: String!) {
    cinemaScheduleItems(
      sort: "startDateTime:ASC"
      where: { endDateTime_gt: $dayAgo }
    ) {
      id
      startDateTime
      startTimePadding
      endDateTime
      endTimePadding
      cinemaPoster {
        id
      }
    }
    cinemaPoster(id: $posterId) {
      id
      title
      subTitle
      director
      producer
      backgroundImages {
        url
      }
    }
    cinemaConfig {
      onAir
    }
    displays(where: { name: $displayName }) {
      displayView {
        backgroundImages {
          url
        }
        overlayImages {
          url
        }
        textColor
      }
    }
  }
`;
