import styled from "@emotion/styled/macro";
import dayjs from "dayjs";

const JobRow = styled.div`
  display: grid;
  grid-template-columns: 8fr 2fr 1fr 3fr 2fr 2fr;
  padding: 1rem 2rem;
  background: #222222;
  border-bottom: 2px solid black;
`;

const JobItem = styled.h3`
  padding: 0 2rem 0 0;
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 1.4rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const ProgressBarWrapper = styled.div`
  padding: 0 1rem 0 0;
`;

const ProgressBar = styled.div`
  height: 100%;
  width: 100%;
  background: #333333;
  display: flex;
`;

const ProgressElement = styled.div`
  width: ${({ value }) => {
    return `${value}%`;
  }};
  height: 100%;
  background-color: ${({ statusColor }) => {
    return statusColor;
  }};
  transition: all 1s ease;
`;

const statuses = {
  1: { name: "Rendering", color: "#50B12B" },
  2: { name: "Queued", color: "#efefef" },
  3: { name: "Active", color: "#efefef" },
  4: { name: "Pending", color: "rgb(238, 127, 36)" },
  5: { name: "Completed", color: "#41acf8" },
  6: { name: "Suspended", color: "#555555" },
  7: { name: "Failed", color: "rgb(214, 24, 24)" },
  8: { name: "Unknown", color: "#efefef" },
};

function Job({ job, progress, textColor }) {
  let statusColor = statuses[job.status].color;
  textColor = textColor || statusColor;
  return (
    <JobRow>
      <JobItem textColor={textColor}>
        {job.name} - {job.plugin}{" "}
      </JobItem>
      <JobItem textColor={textColor}>{job.user}</JobItem>
      <JobItem textColor={textColor}>{job.errs}</JobItem>

      <ProgressBarWrapper>
        <ProgressBar>
          <ProgressElement
            value={progress.progressCompleted * 100}
            statusColor="#41acf8"
          />
          <ProgressElement
            value={progress.progressQueued * 100}
            statusColor="#50B12B"
          />
        </ProgressBar>
      </ProgressBarWrapper>

      <JobItem textColor={textColor}>{progress.estimateParsed}</JobItem>
      <JobItem textColor={textColor}>{statuses[job.status].name}</JobItem>
    </JobRow>
  );
}

function setJobProgress(job) {
  //
  let timePassed = Math.abs(dayjs(job?.dateStart).diff(undefined, "minute"));
  let timePassedSinceCompletion = Math.abs(
    dayjs(job?.dateComp).diff(undefined, "minute")
  );

  let progressCompleted = job.completedChunks / job.tasks;
  let progressQueued = job.renderingChunks / job.tasks;

  let timePerChunk = timePassed / job.completedChunks;
  let remainingChunks = job.tasks - job.completedChunks;
  let estimate = Math.round(timePerChunk * remainingChunks);

  // parse the estimate to human readable (DayJS version is quite complex)
  let estimateParsed =
    job.status === 1
      ? !isNaN(estimate) &&
        isFinite(estimate) &&
        (estimate > 60
          ? `~ ${Math.round((estimate * 10) / 60) / 10} hour`
          : `~ ${Math.round(estimate)} min`)
      : job.status === 5 &&
        (timePassedSinceCompletion > 60
          ? `~ ${Math.round((timePassedSinceCompletion * 10) / 60) / 10} hour`
          : `~ ${Math.round(timePassedSinceCompletion)} min`);

  return {
    timePassedSinceCompletion,
    progressCompleted,
    progressQueued,
    estimate,
    estimateParsed,
  };
}

export default function JobContainer({ job, textColor }) {
  return <Job job={job} progress={setJobProgress(job)} textColor={textColor} />;
}
