import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";

const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});
const sentryLink = new SentryLink();

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.[0]?.extensions?.exception?.output?.statusCode === 401) {
    console.error("there is an 401 error");
    // Logout on 401
    localStorage.removeItem("jwt");
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("jwt");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${JSON.parse(token)}` : "",
    },
  };
});

const client = new ApolloClient({
  link: sentryLink.concat(logoutLink.concat(authLink.concat(httpLink))),
  cache,
});

export default client;
