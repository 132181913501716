import { useState } from "react";
import styled from "@emotion/styled/macro";
import { Global, css } from "@emotion/react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";

import Time from "../Time";
import JobContainer from "./Job";
import dayjs from "dayjs";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  background: black;
  height: 100%;
  overflow: hidden;
  background: ${({ backgroundImages }) =>
      backgroundImages?.length &&
      backgroundImages
        .map(
          (backgroundImage) =>
            `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${backgroundImage.url}"), `
        )
        .join("")}
    black;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ overlayImages }) =>
      overlayImages &&
      overlayImages
        .map(
          (overlayImage) =>
            `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${overlayImage.url}")`
        )
        .join(", ")};
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`;

const Title = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 4rem;
  line-height: 4rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const StyledTime = styled(Time)`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 4rem;
  line-height: 4rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const JobsTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobsHeader = styled.div`
  display: grid;
  grid-template-columns: 8fr 2fr 1fr 3fr 2fr 2fr;
  padding: 1rem 2rem;
  background: #111111;
`;

const JobsHeaderItem = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 1.4rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const fontSizes = {
  15: "15px",
  16: "14px",
  17: "14px",
  18: "13px",
  19: "13px",
};

function DeadlineJobs({ jobs, backgroundImages, overlayImages, textColor }) {
  let fontSize = "16px";

  if (jobs.length <= 14) {
    fontSize = "16px";
  } else if (jobs.length > 14 && jobs.length < 19) {
    fontSize = fontSizes[jobs.length];
  } else {
    fontSize = "12px";
  }

  return (
    <Main backgroundImages={backgroundImages} overlayImages={overlayImages}>
      <Global
        styles={css`
          html {
            font-size: ${fontSize};
          }
        `}
      />
      <Header>
        <Title textColor={textColor}>render status</Title>
        <StyledTime />
      </Header>
      <JobsTable>
        <JobsHeader>
          <JobsHeaderItem textColor={textColor}>job name</JobsHeaderItem>
          <JobsHeaderItem textColor={textColor}>user</JobsHeaderItem>
          <JobsHeaderItem textColor={textColor}>errors</JobsHeaderItem>
          <JobsHeaderItem textColor={textColor}>task progress</JobsHeaderItem>
          <JobsHeaderItem textColor={textColor}>estimate</JobsHeaderItem>
          <JobsHeaderItem textColor={textColor}>status</JobsHeaderItem>
        </JobsHeader>
        {jobs?.map((job) => {
          return <JobContainer job={job} key={job.id} textColor={textColor} />;
        })}
      </JobsTable>
    </Main>
  );
}

export default function DeadlineJobsContainer() {
  let { displayName } = useParams();

  // component caches by itself because networklatency creates flickers
  let [jobs, setJobs] = useState([]);

  let { data } = useQuery(DISPLAYS_DEADLINE_QUERY, {
    variables: {
      displayName,
      dateUpdatedFrom: dayjs()
        .startOf("minute")
        .subtract(2, "hour")
        .toISOString(),
    },
    pollInterval: 1000,
    onCompleted: (data) => {
      setJobs(data?.deadlineJobs);
    },
  });

  return (
    <DeadlineJobs
      jobs={jobs}
      backgroundImages={data?.displays?.[0]?.displayView?.backgroundImages}
      overlayImages={data?.displays?.[0]?.displayView?.overlayImages}
      textColor={data?.displays?.[0]?.displayView?.textColor}
    />
  );
}

const DISPLAYS_DEADLINE_QUERY = gql`
  query DisplaysAndDeadline($displayName: String!, $dateUpdatedFrom: String!) {
    deadlineJobs(
      where: { updated_at_gt: $dateUpdatedFrom }
      limit: 20
      sort: "status:asc,dateComp:desc,dateStart:desc"
    ) {
      id
      deadlineId
      name
      plugin
      status
      user
      errs
      completedChunks
      tasks
      renderingChunks
      dateStart
      dateComp
    }
    displays(where: { name: $displayName }) {
      displayView {
        textColor
        backgroundImages {
          url
        }
        overlayImages {
          url
        }
      }
    }
  }
`;
