import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

export default function Date({ className }) {
  let [timeStamp, setTimeStamp] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStamp(dayjs());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  let day = timeStamp.format("dddd");
  let month = timeStamp.format("DD MMMM");

  return (
    <h2 className={className}>
      {day} &mdash; {month}
    </h2>
  );
}
