import _ from "lodash";

let deadlines = [
  { name: "all assets finished tomorrow because of deadline", progress: "80%" },
  { name: "all shots deadline", progress: "20%" },
  { name: "keep director happy, always very important ", progress: "50%" },
];
let statusItems = [
  { status: "pre-production", progress: 20, color: "#444444" },
  { status: "waiting", progress: 20, color: "#A8A8A8" },
  { status: "in progress", progress: 15, color: "#FC2A92" },
  { status: "changes required", progress: 25, color: "#41ACF8" },
  { status: "accepted", progress: 20, color: "#50B12B" },
];
let randomShot = {
  sequence: "sc012",
  shotCode: "fx010",
  status: "accepted",
  color: "#50B12B",
};
let sprint = {
  name: "Week 16",
  dateStart: "9 - ",
  dateStop: "15 march",
  sequences: _.groupBy(
    [
      { ...randomShot, sequence: "sc074" },
      { ...randomShot, sequence: "sc074" },
      { ...randomShot, sequence: "sc074" },
      randomShot,
      randomShot,
      randomShot,
    ],
    "sequence"
  ),
};
let sprints = [
  sprint,
  { ...sprint, name: "Week 17" },
  { ...sprint, name: "Week 18" },
  { ...sprint, name: "Week 19" },
];
let randomProject = {
  shortTitle: "N10",
  title: "Zee van Tijd",
  sprints,
  daysLeft: 89,
  deadlines,
  statusItems,
};
let projects = [
  { ...randomProject, shortTitle: "ZVT" },
  { ...randomProject, shortTitle: "DGI" },
  randomProject,
];

// calculate shotcount, get rid of this monstrosity as soon as possible
projects = projects.map((project) => {
  let shotCount = project.sprints.reduce((acc, sprint) => {
    return (
      _.reduce(
        sprint.sequences,
        (acc, shots) => {
          return shots.length + acc;
        },
        0
      ) + acc
    );
  }, 0);
  return { ...project, shotCount };
});

let projectsData = projects;
export default projectsData;
