import styled from "@emotion/styled/macro";

import Time from "../Time";

import saLogo from "./sa_banner_cut.png";

const FooterWrapper = styled.footer`
  flex: 0 0 12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
`;

const SALogoImg = styled.img`
  height: 10rem;
`;

const StyledTime = styled(Time)`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 4rem;
  color: #efefef;
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <SALogoImg src={saLogo} alt="" />
      <StyledTime />
    </FooterWrapper>
  );
}
