import { useState } from "react";

import styled from "@emotion/styled/macro";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import Time from "../Time";
import Date from "../Date";
import useInterval from "../../helpers/useInterval";

import wallpaper from "./wallpaper.jpg";

const ClockViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: ${({ backgroundImages }) =>
      backgroundImages?.length
        ? backgroundImages
            .map(
              (backgroundImage) =>
                `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${backgroundImage.url}"), `
            )
            .join("")
        : `no-repeat center/cover url(${wallpaper}), `}
    black;
  height: 100%;
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ overlayImages }) =>
      overlayImages &&
      overlayImages
        .map(
          (overlayImage) =>
            `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${overlayImage.url}")`
        )
        .join(", ")};
  }
`;

const ClockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTime = styled(Time)`
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  font-family: "ScandiaWebBold", sans-serif;
  perspective: 600px;
  font-size: ${({ clockSize }) => (clockSize === "small" ? "200px" : "500px")};
`;

const StyledDate = styled(Date)`
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  font-family: "Fresco";
  font-size: 60px;
`;

const AnnouncementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  font-family: "ScandiaWebBold";
  font-size: 300px;
`;

const SubTitle = styled.h2`
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
  font-family: "ScandiaWebBold";
  font-size: 120px;
`;

function Clock({ backgroundImages, overlayImages, announcement, textColor }) {
  return (
    <ClockViewWrapper
      backgroundImages={backgroundImages}
      overlayImages={overlayImages}
    >
      {announcement && (
        <AnnouncementContainer>
          {announcement.subTitle && (
            <SubTitle textColor={textColor}>{announcement.subTitle}</SubTitle>
          )}
          {announcement.title && (
            <Title textColor={textColor}>{announcement.title}</Title>
          )}
        </AnnouncementContainer>
      )}
      <ClockWrapper>
        <StyledTime clockSize={announcement && "small"} textColor={textColor} />
        <StyledDate textColor={textColor} />
      </ClockWrapper>
    </ClockViewWrapper>
  );
}

let startOfMinute = () => dayjs().startOf("minute").toISOString();

function ClockContainer() {
  // set and make sure the time gets updated so new data is requested
  const [now, setNow] = useState(startOfMinute());
  useInterval(() => setNow(startOfMinute()), 1000);

  // Setup an additional cache for the data, for the slow network of the raspberries
  const [cachedData, setCachedData] = useState();

  let { displayName } = useParams();
  let { loading, error } = useQuery(DISPLAYS_AND_CLOCK_SCHEDULE_QUERY, {
    variables: {
      displayName,
      now,
    },
    onCompleted: (data) => setCachedData(data),
    pollInterval: 10000,
  });

  if (!displayName || loading || error || cachedData?.displays?.length === 0)
    return <Clock />;

  let viewData = cachedData?.displays?.[0]?.displayView;
  let scheduleItem = cachedData?.clockScheduleItems?.[0];

  return (
    <Clock
      backgroundImages={
        scheduleItem?.displayView?.backgroundImages ||
        viewData?.backgroundImages
      }
      overlayImages={
        scheduleItem?.displayView?.overlayImages || viewData?.overlayImages
      }
      textColor={scheduleItem?.displayView?.textColor || viewData?.textColor}
      announcement={
        scheduleItem && {
          title: scheduleItem.title,
          subTitle: scheduleItem.subTitle,
        }
      }
    />
  );
}

export default ClockContainer;

const DISPLAYS_AND_CLOCK_SCHEDULE_QUERY = gql`
  query DisplaysAndClockSchedule($displayName: String!, $now: String!) {
    displays(where: { name: $displayName }) {
      displayView {
        textColor
        backgroundImages {
          url
        }
        overlayImages {
          url
        }
      }
    }
    clockScheduleItems(
      limit: 1
      sort: "startDateTime:ASC"
      where: { startDateTime_lt: $now, endDateTime_gt: $now }
    ) {
      id
      title
      subTitle
      startDateTime
      endDateTime
      displayView {
        textColor
        backgroundImages {
          url
        }
        overlayImages {
          url
        }
      }
    }
  }
`;
