import styled from "@emotion/styled/macro";
import rectangle from "./rectangle.png";

const SprintsWrapper = styled.div`
  flex: 5 1;
  display: flex;
  width: 100%;
  padding: 1rem;
  background: #222222;
  border: 2px solid black;
`;

const CurrentSprint = styled.div`
  flex: 0 0 66.66%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0 0 1rem 0;
`;

const WeekTitle = styled.h3`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 2.5rem; // 2.06
  line-height: 3rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const Text = styled.p`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 1.5rem; // 1.5
  line-height: 1.5rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

const Date = styled(Text)`
  padding: 0 0 0 3rem;
  line-height: 2.4rem;
`;

// sequence
const SequenceWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding: 0 0 1rem 0;
`;

const SequenceHeader = styled.div``;

const Thumb = styled.img`
  display: block;
  width: 6rem;
  margin: 0 1rem 0 0;
`;

const SequenceBody = styled.div``;

const Entity = styled.div``;

const StatusBlock = styled.div``;

const FutureSprintWrapper = styled.div`
  flex: 0 0 33.33%;
`;

const FutureSprintList = styled.div``;

function Sequence() {
  return (
    <SequenceWrapper>
      <SequenceHeader>
        <Thumb src={rectangle} />
      </SequenceHeader>
      <SequenceBody>
        <Text>sc0156</Text>
        <Entity>
          <StatusBlock></StatusBlock>
          <Text>fx0110</Text>
        </Entity>
        <Entity>
          <StatusBlock></StatusBlock>
        </Entity>
        <Entity>
          <StatusBlock></StatusBlock>
        </Entity>
        <Entity>
          <StatusBlock></StatusBlock>
        </Entity>
        <Entity>
          <StatusBlock></StatusBlock>
        </Entity>
      </SequenceBody>
    </SequenceWrapper>
  );
}

function FutureSprint({ sprint }) {
  return (
    <FutureSprintWrapper>
      <WeekTitle>{sprint.name}</WeekTitle>
    </FutureSprintWrapper>
  );
}

export default function Sprints({ project }) {
  let [currentSprint, ...futureSprints] = project.sprints;
  return (
    <SprintsWrapper>
      <CurrentSprint>
        <Header>
          <WeekTitle>{currentSprint.name}</WeekTitle>
          <Date>
            {currentSprint.dateStart} {currentSprint.dateStop}
          </Date>
        </Header>
        <Sequence></Sequence>
      </CurrentSprint>
      <FutureSprintList>
        {futureSprints.map((sprint, key) => {
          return <FutureSprint key={key} sprint={sprint} />;
        })}
      </FutureSprintList>
    </SprintsWrapper>
  );
}
