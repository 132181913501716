import { css } from "@emotion/react/macro";
import scandiaEot from "./static/fonts/scandia/scandiaWebBold.eot";
import scandiaWoff from "./static/fonts/scandia/scandiaWebBold.woff";
import scandiaWoff2 from "./static/fonts/scandia/scandiaWebBold.woff2";
import frescoProEot from "./static/fonts/frescoPro/FrescoPro-Normal.eot";
import frescoProWoff from "./static/fonts/frescoPro/FrescoPro-Normal.woff";
import frescoProTTF from "./static/fonts/frescoPro/FrescoPro-Normal.ttf";
import frescoProItalicEot from "./static/fonts/frescoPro/FrescoPro-NormalItalic.eot";
import frescoProItalicWoff from "./static/fonts/frescoPro/FrescoPro-NormalItalic.woff";
import frescoProItalicTTF from "./static/fonts/frescoPro/FrescoPro-NormalItalic.ttf";

export const cssReset = css`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  html,
  body,
  #root,
  .App {
    height: 100%;
    width: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol,
  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const fonts = css`
  @font-face {
    font-family: "ScandiaWebBold";
    src: url("${scandiaEot}");
    src: url("${scandiaEot}?#iefix") format("embedded-opentype"),
      url("${scandiaWoff2}") format("woff2"),
      url("${scandiaWoff}") format("woff");
  }
  @font-face {
    font-family: "Fresco";
    src: url("${frescoProEot}");
    src: url("${frescoProEot}") format("â˜º"),
      url("${frescoProWoff}") format("woff"),
      url("${frescoProTTF}") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Fresco Pro";
    src: url("${frescoProItalicEot}");
    src: url("${frescoProItalicEot}?") format("â˜º"),
      url("${frescoProItalicWoff}") format("woff"),
      url("${frescoProItalicTTF}") format("truetype");
    font-weight: normal;
    font-style: italic;
  }
`;
