import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import CinemaPlanning from "./CinemaSchedule";
import CinemaPosterContainer from "./CinemaPoster";

export default function CinemaViewContainer() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/poster/:posterId`}>
        <CinemaPosterContainer />
      </Route>
      <Route path={`${path}/schedule`}>
        <CinemaPlanning />
      </Route>
      <Redirect
        exact
        from={path}
        to={{
          pathname: `${path}/schedule`,
          search: "?automatic=true",
        }}
      />
    </Switch>
  );
}
