import { useState, useEffect } from "react";

import styled from "@emotion/styled/macro";

import Navigation from "./Navigation";
import Deadlines from "./Deadlines";
import Sprints from "./Sprints";
import Conversations from "./Conversations";

import projectsData from "./projectsData";

import axios from "../../api/axiosShotgunInstance";

// import sprintData from "./sprint.json";
// import shotData from "./shot.json";
// console.log(sprintData, shotData);

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  padding: 1rem;
  background: ${({ backgroundImages }) =>
      backgroundImages?.length &&
      backgroundImages
        .map(
          (backgroundImage) =>
            `no-repeat center/cover url("${process.env.REACT_APP_BACKEND_URL}${backgroundImage.url}"), `
        )
        .join("")}
    black;
`;

const ProjectBody = styled.div`
  flex: 1 1;
  display: flex;
`;

const ProjectDetails = styled.div`
  flex: 0 0 66.666%;
  display: flex;
  flex-direction: column;
`;

function Shotgun() {
  let projects = projectsData;
  let currentProject = projects[0];
  return (
    <Wrapper>
      <Navigation projects={projects} activeProject={currentProject} />
      <ProjectBody>
        <ProjectDetails>
          <Deadlines project={currentProject} />
          <Sprints project={currentProject} />
        </ProjectDetails>
        <Conversations project={currentProject} />
      </ProjectBody>
    </Wrapper>
  );
}

export default function ShotgunContainer() {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      const result = await axios("/entity/shot/9809");
      setData(result.data);
    }
    fetchData();
  }, []);

  console.log(data);

  return <Shotgun />;
}
