import styled from "@emotion/styled/macro";
import Time from "../Time";
import ProjectStatusBar from "./ProjectStatusBar";

const NavigationBar = styled.main`
  display: flex;
  width: 100%;
`;

const NavItemList = styled.div`
  display: flex;
  width: 100%;
`;

const NavItemWrapper = styled.div`
  width: calc(100% / 5);
  background: #222222;
  border: 2px solid black;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 1rem 0 1rem;
`;

const ProjectShortTitle = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 3.5rem; // 3.43
  line-height: 4rem;
  color: ${({ active }) => (active ? "#efefef" : "#444444")};
`;

const ShotCount = styled.h3`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 2rem; // 2.06
  line-height: 3.4rem;
  color: ${({ active }) => (active ? "#efefef" : "#444444")};
`;

const StyledTime = styled(Time)`
  display: flex;
  justify-content: flex-end;
  width: calc(100% / 5);
  padding: 1rem;
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 4rem;
  line-height: 4rem;
  color: ${({ textColor }) => (textColor ? textColor : "#efefef")};
`;

function NavItem({ project, active }) {
  return (
    <NavItemWrapper>
      <ProjectStatusBar project={project} />
      <Details>
        <ProjectShortTitle active={active}>
          {project.shortTitle}
        </ProjectShortTitle>
        <ShotCount active={active}>{project.shotCount}shots</ShotCount>
      </Details>
    </NavItemWrapper>
  );
}

export default function Navigation({ projects, activeProject }) {
  return (
    <NavigationBar>
      <NavItemList>
        {projects.map((project, key) => {
          return (
            <NavItem
              key={key}
              project={project}
              active={project.shortTitle === activeProject.shortTitle}
            />
          );
        })}
      </NavItemList>
      <StyledTime />
    </NavigationBar>
  );
}
