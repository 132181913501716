import styled from "@emotion/styled/macro";

const ProjectInfoWrapper = styled.div``;

const H2 = styled.h2`
  font-family: "ScandiaWebBold", sans-serif;
  color: #efefef;
  font-size: 1.2rem;
  line-height: 1.3rem;
`;

export default function ProjectInfo() {
  return (
    <ProjectInfoWrapper>
      <H2>{navigator.userAgent}</H2>
      <H2>version: {process.env.REACT_APP_VERSION || "undefined"}</H2>
      <H2>backend url: {process.env.REACT_APP_BACKEND_URL || "undefined"}</H2>
      <br />
      <H2>tag: {process.env.REACT_APP_BITBUCKET_TAG || "undefined"}</H2>
      <H2>branch: {process.env.REACT_APP_BITBUCKET_BRANCH || "undefined"}</H2>
      <H2>commit: {process.env.REACT_APP_BITBUCKET_COMMIT || "undefined"}</H2>
      <H2>
        build: {process.env.REACT_APP_BITBUCKET_BUILD_NUMBER || "undefined"}
      </H2>
    </ProjectInfoWrapper>
  );
}
