import styled from "@emotion/styled/macro";

const ProjectStatusBarWrapper = styled.div`
  display: flex;
`;

const StatusItem = styled.div`
  background: ${({ statusItem }) => statusItem.color};
  width: ${({ statusItem }) => `${statusItem.progress}%`};
  height: ${({ type }) => (type === "large" ? "1rem" : "0.5rem")}; ;
`;

export default function ProjectStatusBar({ project, type }) {
  return (
    <ProjectStatusBarWrapper type={type}>
      {project.statusItems.map((statusItem, key) => {
        return <StatusItem key={key} statusItem={statusItem} type={type} />;
      })}
    </ProjectStatusBarWrapper>
  );
}
