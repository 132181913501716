import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export function isPaddedStartPassed(cinemaScheduleItem) {
  let startTimePadding = cinemaScheduleItem.startTimePadding;
  let startDuration = dayjs.duration({
    hours: startTimePadding?.split(":")[0],
    minutes: startTimePadding?.split(":")[1],
  });

  return (
    dayjs(cinemaScheduleItem.startDateTime).subtract(startDuration) < dayjs()
  );
}

export function isPaddedEndPassed(cinemaScheduleItem) {
  let endTimePadding = cinemaScheduleItem.endTimePadding;

  let endDuration = dayjs.duration({
    hours: endTimePadding?.split(":")[0],
    minutes: endTimePadding?.split(":")[1],
  });

  return dayjs(cinemaScheduleItem.endDateTime).add(endDuration) < dayjs();
}

export function isCurrentCinemaScheduleItem(cinemaScheduleItem) {
  return (
    isPaddedStartPassed(cinemaScheduleItem) &&
    !isPaddedEndPassed(cinemaScheduleItem)
  );
}
