import styled from "@emotion/styled/macro";

const Wrapper = styled.div`
  background: black;
  width: 100%;
  height: 100%;
`;

export default function Blank() {
  return <Wrapper>Black screen</Wrapper>;
}
