import styled from "@emotion/styled/macro";

const OnAirOuterWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const OnAirWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10rem 0rem;
  background-color: rgba(255, 0, 0, 0.85);
`;

const OnAirTitle = styled.h1`
  font-family: "ScandiaWebBold", sans-serif;
  font-size: 12.5rem;
  line-height: 12.5rem;
  color: #efefef;
`;

export default function OnAir() {
  return (
    <OnAirOuterWrapper>
      <OnAirWrapper>
        <OnAirTitle>ON AIR</OnAirTitle>
      </OnAirWrapper>
    </OnAirOuterWrapper>
  );
}
