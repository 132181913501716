import axios from "axios";
import qs from "query-string";

const axiosInstance = axios.create({
  baseURL: "https://shotgunproxy.rjkorteschiel.workers.dev/api/v1",
  timeout: 1000,
  headers: { "X-Custom-Header": "foobar" },
});

const credentials = {
  grant_type: "client_credentials",
  client_id: process.env.REACT_APP_SHOTGUN_CLIENT_ID,
  client_secret: process.env.REACT_APP_SHOTGUN_CLIENT_SECRET,
};

async function authenticate() {
  const options = {
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify(credentials),
    url: "/auth/access_token",
  };

  let { data } = await axiosInstance(options);

  localStorage.setItem("shotgunjwt", data["access_token"]);
}

// authenticate();

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("shotgunjwt");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
